.submit-report-container {
  --bs-gutter-x: 0;
  margin: 40px auto;
  width: 300px;
}

.report-form-submit {
  display: block;
  margin: 0 auto;
  min-width: max-content;
  background-color: #00ad8e;
  border: none;
}

.report-form-submit:hover {
  background-color: #00866d;
}

@media (max-width: 458px) {
  .submit-report-container {
    width: 100%;
  }
}
