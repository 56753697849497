.report-control {
  --bs-gutter-x: 0;
  gap: 16px;
}

.report-control-btn-container {
  flex-grow: 0;
}

.clear-report-btn {
  min-width: max-content;
}

.update-location-btn {
  font-size: 20px;
  min-width: max-content;
  max-width: 40px;
  background-color: transparent;
  color: inherit;
  border: none;
}

.update-location-btn:hover {
  background-color: #9fa5aa57;
  color: inherit;
}

#current-station-container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

#current-station-container > p {
  min-width: 250px;
  margin-bottom: 0;
  align-self: center;
}

#report-template-container {
  margin: 30px 0;
}

@media (max-width: 768px) {
  .update-location-btn:hover {
    background-color: transparent;
  }

  .update-location-btn:active {
    background-color: #9fa5aa57;
  }
}

@media (max-width: 458px) {
  .report-control {
    flex-direction: column;
  }

  .subpackage-dropdown-toggle + .dropdown-menu,
  .template-dropdown-toggle + .dropdown-menu {
    width: 100%;
    text-align: center;
  }

  .subpackage-dropdown-toggle,
  .template-dropdown-toggle,
  .clear-report-btn,
  .update-location-btn {
    width: 100%;
  }
}
