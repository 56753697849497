/* fix for bootstrap modal windows */
body {
  padding-right: 0 !important;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

/* Custom spinner */
.spinner-loading-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner-loading {
  color: #00ad8e;
  width: min(75vw, 75vh);
  height: min(75vw, 75vh);
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

/* qualomate spinner styles */
.spinner-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner > img {
  height: 100%;
  width: 100%;
}
/*  */

.main-content {
  --bs-gutter-x: 0;
  max-width: none;
}

.result-block-container .result-block {
  opacity: 1;
}

.result-block {
  position: relative;
  width: 100%;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

@media (max-width: 458px) {
  .dropdown,
  .dropdown-toggle,
  .dropdown-menu {
    width: 100%;
  }
}
