#navbar {
  padding: 10px 14px;
}

.navbar > .container {
  max-width: none;
}

.navbar-brand {
  margin: 0;
  margin-right: 0;
}

.nav-logo {
  height: 35px;
}

.navbar-toggler {
  margin-right: 0;
}

#navbar-profile {
  margin-top: 0;
  padding: 16px 20px;
}

#qsLoginBtn {
  background-color: #00ad8e;
  border: none;
  cursor: pointer;
}
