/* remove default 50% width prop on tags input */
.rti--input {
  width: 100%;
}

.form-section-separator-container {
  display: flex;
  align-items: center;
  margin: 40px 0 10px;
}

.form-section-separator {
  flex: 1;
  border-bottom: 1px solid #bfc4c8;
}

.form-section-separator-title {
  margin: 0 10px;
}

.incident-form-container {
  max-width: 650px;
  margin: 30px auto;
  padding: 0 20px;
}

.incident-form-title {
  text-align: center;
  margin: 30px auto;
}

.incident-form-body label {
  font-weight: 500;
}

.incident-form-submit {
  display: block;
  margin: 40px auto;
  width: 50%;
  min-width: max-content;
  background-color: #00ad8e;
  border: none;
}

.incident-form-submit:hover {
  background-color: #00866d;
}

@media (max-width: 458px) {
  .incident-form-submit {
    width: 100%;
  }
}
